import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import KeyboardControls from 'app/utils/SliderKeyboardControls'
import { useVocabularyData } from 'app/utils/vocabulary'
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

const AdaptiveHeight: KeenSliderPlugin = (slider) => {
  function updateHeight() {
    slider.container.style.height =
      slider.slides[slider.track.details.rel].offsetHeight + 'px'
  }
  slider.on('created', updateHeight)
  slider.on('slideChanged', updateHeight)
}

export interface Props {
  languageCode: string
  slides: SlideProps[]
}

export const News = memo(function News({ languageCode, slides }: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      drag: false,
      slides: slides.length,
      loop: slides.length > 1 ? true : false,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
      detailsChanged(s) {
        const new_opacities = s.track.details.slides.map(
          (slide: any) => slide.portion,
        )
        setOpacities(new_opacities)
      },
      breakpoints: {
        '(max-width: 1023px)': {
          drag: true,
          loop: true,
          slides: {
            perView: 1.01,
            spacing: 17,
          },
        },
      },
    },
    [AdaptiveHeight, KeyboardControls],
  )

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef && slides.length > 1) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Wrapper>
        <Title>{useVocabularyData('news', languageCode)}</Title>
        <Slider ref={sliderRef}>
          {uniq(slides).map((item, index) => (
            <Slide
              className="keen-slider__slide"
              key={index}
              style={{ opacity: opacities[index] }}
              {...item}
            />
          ))}
        </Slider>

        {slides && slides.length > 1 ? (
          <>
            <Counter>{`${currentSlide + 1}/${slides.length}`}</Counter>
            <Arrows row>
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              />
            </Arrows>
          </>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 13.75rem 11.944vw 8.5rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding: 4.75rem 2rem 5rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  text-align: right;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  width: calc(62% - 10.972vw);
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.1875rem;
  line-height: 2.25rem;
  margin: 0 7.986vw 0 auto;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    flex: 1;
    margin-right: 1.5rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 1.125rem;
    letter-spacing: 0;
    line-height: 1.5rem;
  }
`

const Slider = styled.div`
  display: flex;
  outline: none;
  margin-top: 1rem;
  position: relative;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    height: auto !important;
    margin-top: 2.375rem;
    overflow: visible !important;
  }
`

const Counter = styled.div`
  width: 30%;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  text-align: center;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: -2.5rem;
  left: calc(30% + 7.431vw);
  z-index: 2;

  @media (max-width: 1023px) {
    display: none;
  }
`
