import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  className?: string
  description?: string
  image?: ImageProps
  style?: {}
  title?: string
}

export const Slide = memo(function Slide({
  cta,
  className,
  description,
  image,
  style,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container className={className} style={style}>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {cta ? <CTA variant="simple" {...cta} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100% !important;
  height: max-content !important;
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;

  @media (min-width: 1024px) {
    transform: none !important;
  }

  @media (max-width: 1023px) {
    opacity: 1 !important;
    position: relative;
    top: auto;
    left: auto;
  }
`

const Background = styled.div`
  width: 38%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-3.75rem);

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 63%;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    transform: none;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  width: 70%;
  min-height: 26.0625rem;
  margin-left: auto;
  padding: 5.625rem 7.778vw 7.25rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    width: 100%;
    min-height: 0;
    padding: 2.5rem 2rem 3.75rem;
    text-align: center;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.25rem;

  @media (max-width: 1023px) {
    margin-top: 0.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
